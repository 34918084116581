//import { useState, useEffect } from 'react';
import './App.css';

// Loading Dots
const LoadingDots = () => {
  return (
    <div className="Loading-dots">
      <div className="Dot"></div>
      <div className="Dot"></div>
      <div className="Dot"></div>
    </div>
  )
}

const App = () => {
  return (
    <div className="App">
      
      <div className="Title">BinaryLoom.dev</div>
      
      <div className="NavBar">
        <div className="NavLinks">
          <a href='#Home'>Home</a>
          <a href='#Development'>Development</a>
          <a href='#Photography'>Photography</a>
          <a href='#Home'>About</a>
        </div>
      </div>

      <div className='body'>
        <LoadingDots/>
      </div>
      
        
        
    </div>
  );
}

export default App;
